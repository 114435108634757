.rf-DataCard {
	flex-basis: 20%;
	margin: 5px;
	min-width: 222px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: var(--color__gray);
	box-shadow: var(--drop-shadow__inverse);
	transition: all 0.5s ease-in-out;
}

.rf-DataCard:last-child {
	margin-right: auto;
}

.rf-DataCard:hover {
	box-shadow: var(--drop-shadow__regular);
}

.rf-DataCard .rf-Separator {
	margin: 0 auto;
	width: calc(100% - 10px);
	background: var(--color__gray--light);
}

.rf-DataCard-thumbnail {
	width: 100%;
	height: 120px;
}

.rf-DataCard-thumbnail > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rf-DataCard-title {
	padding: 10px;
	font-size: var(--font__size--big);
	text-transform: uppercase;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}

.rf-DataCard-footer {
	padding: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: var(--font__size--small);
}

.rf-DataCard-footer > .rf-Avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 10px;
}
