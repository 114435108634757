@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700);
/* Toast Animations */

@-webkit-keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		        transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		        transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}

/* Header Animations */

@-webkit-keyframes typing {
	from {
		width: 100%;
	}

	to {
		width: 0;
	}
}

@keyframes typing {
	from {
		width: 100%;
	}

	to {
		width: 0;
	}
}

@-webkit-keyframes cursor {
	50% {
		border-color: var(--color__secondary);
	}
}

@keyframes cursor {
	50% {
		border-color: var(--color__secondary);
	}
}

/* Loader Animations */

@-webkit-keyframes pulse_1 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(0);
		        transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1.5);
		        transform: translate3d(0, 0, 0) scale(1.5);
		opacity: 0;
	}
}

@keyframes pulse_1 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(0);
		        transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1.5);
		        transform: translate3d(0, 0, 0) scale(1.5);
		opacity: 0;
	}
}

@-webkit-keyframes pulse_2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(0);
		        transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
		        transform: translate3d(0, 0, 0) scale(1);
		opacity: 0;
	}
}

@keyframes pulse_2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(0);
		        transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
		        transform: translate3d(0, 0, 0) scale(1);
		opacity: 0;
	}
}

.cm-s-ratefolio.CodeMirror, .cm-s-ratefolio .CodeMirror-gutters {
	background-color: var(--color__gray) !important;
	color: var(--color__white) !important;
	border: 1px solid var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-gutters {
	color: var(--color__gray--light) !important;
	border: none;
	border-right: 1px solid var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-sizer {
	padding: 10px 0;
}

.cm-s-ratefolio .CodeMirror-cursor {
	border-left: solid thin var(--color__white--dark);
}

.cm-s-ratefolio .CodeMirror-linenumber {
	color: var(--color__pink);
}

.cm-s-ratefolio .CodeMirror-selected {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio .CodeMirror-line::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span > span::-moz-selection {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio .CodeMirror-line::selection, .cm-s-ratefolio .CodeMirror-line > span::selection, .cm-s-ratefolio .CodeMirror-line > span > span::selection {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio .CodeMirror-line::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span > span::-moz-selection {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio span.cm-comment {
	color: #6272a4;
}

.cm-s-ratefolio span.cm-string, .cm-s-ratefolio span.cm-string-2 {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-number {
	color: #bd93f9;
}

.cm-s-ratefolio span.cm-variable {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-variable-2 {
	color: var(--color__white);
}

.cm-s-ratefolio span.cm-def {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-operator {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-keyword {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-atom {
	color: #bd93f9;
}

.cm-s-ratefolio span.cm-meta {
	color: #f8f8f2;
}

.cm-s-ratefolio span.cm-tag {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-attribute {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-qualifier {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-property {
	color: #66d9ef;
}

.cm-s-ratefolio span.cm-builtin {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-variable-3, .cm-s-ratefolio span.cm-type {
	color: #ffb86c;
}

.cm-s-ratefolio .CodeMirror-activeline-background {
	background: var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-matchingbracket {
	text-decoration: underline;
	color: var(--color__white) !important;
}

/* Variables */

:root {

	/* Colors */

	--color__white: #f0f0f7;
	--color__white--dark: #b7b7cd;
	--color__gray: #353740;
	--color__gray--light: #5e5d66;
	--color__gray--dark: #21212a;
	--color__pink: #f7537c;

	--color__primary: var(--color__gray);
	--color__secondary: var(--color__pink);

	/* Body */

	--bg__color: var(--color__gray--dark);
	--drop-shadow__regular: 10px 0 37.6px 2.4px rgba(0, 0, 0, 0.2);
	--drop-shadow__inverse: -10px 0 37.6px 2.4px rgba(0, 0, 0, 0.2);

	/* Fonts */

	--font__color--primary: var(--color__white);
	--font__color--dark: var(--color__white--dark);
	--font__color--secondary: var(--color__pink);

	--font__size--base: 12px;
	--font__size--tiny: 8px;
	--font__size--small: 10px;
	--font__size--big: 16px;
	--font__size--giant: 32px;

	/* Content */

	--content__maxWidth: 936px;
	--content__minHeight: calc(100vh - var(--main-menu__height));

	/* Main Menu */

	--main-menu__height: 50px;
	--main-menu__bg: var(--color__gray);

	/* Buttons */

	--button__height: 32px;
	--button__width--min: 144px;
	--button__width--max: calc(var(--button__width--min)*2);
	--button__drop-shadow: 0 10px 20px 2px rgba(0,0,0,0.3);

	/* Toaster */

	--toaster__bg: var(--color__gray);

	/* Animbations */

	--animation__slide_in--up: slideInUp 1s;
	--animation__typing: typing 2s steps(16) forwards, cursor 1s infinite;

	/* stylelint-disable */

	--animation__pulse--1: pulse_1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
	--animation__pulse--2: pulse_2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;

	/* stylelint-enable */
}

/* Global */

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto Mono', 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #21212a;
	background: var(--bg__color);
	font-weight: 500;
	font-size: 12px;
	font-size: var(--font__size--base);
	color: #f0f0f7;
	color: var(--font__color--primary);
}

code {
	font-family: 'Roboto Mono', 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
}

a {
	text-decoration: none;
	color: #f0f0f7;
	color: var(--font__color--primary);
}

.rf-SectionHeader > h1,
.rf-SectionHeader > h2,
.rf-SectionHeader > h3,
.rf-SectionHeader > h4,
.rf-SectionHeader > h5,
.rf-SectionHeader > h6 {
	margin: 0;
	position: relative;
	display: inline-block;
	font-weight: 400;
	color: var(--font__color--dark);
}

.rf-SectionHeader > h1 span,
.rf-SectionHeader > h2 span,
.rf-SectionHeader > h3 span,
.rf-SectionHeader > h4 span,
.rf-SectionHeader > h5 span,
.rf-SectionHeader > h6 span {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	background: var(--bg__color);
	border-left: 2px solid transparent;
	-webkit-animation: var(--animation__typing);
	        animation: var(--animation__typing);
}

.rf-Separator {
	background: var(--color__secondary);
}

.rf-Separator-horizontal {
	height: 1px !important;
	margin: 40px 0;
}

.rf-Separator-vertical {
	width: 1px !important;
	margin: 0 40px;
}

.rf-TextBlock {
	text-align: justify;
	align-self: flex-start;
	margin-top: 20px;
	color: var(--font__color--dark);
}

.rf-TextBlock > span {
	color: var(--font__color--primary);
	font-weight: 700;
}

.rf-TextBlock > a {
	color: var(--font__color--secondary);
	font-weight: 700;
}

/* stylelint-disable */

.rf-Spinner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rf-Spinner span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}

.rf-Spinner span:before,
.rf-Spinner span:after {
	content: '';
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 2px solid #FFF;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: var(--animation__pulse--1);
	        animation: var(--animation__pulse--1);
}

.rf-Spinner span:after {
	-webkit-animation: var(--animation__pulse--2);
	        animation: var(--animation__pulse--2);
}

.rf-PageWrapper {
	height: var(--content__minHeight);
	max-width: var(--content__maxWidth);
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	position: relative;
}

.rf-PageWrapper-vertical {
	flex-direction: column;
	padding-top: 40px;
}

.rf-PageWrapper-horizontal {
	flex-direction: row;
	justify-content: center;
}

.rf-Avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
}

.rf-Avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rf-DataGrid {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.rf-DataCard {
	flex-basis: 20%;
	margin: 5px;
	min-width: 222px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: var(--color__gray);
	box-shadow: var(--drop-shadow__inverse);
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.rf-DataCard:last-child {
	margin-right: auto;
}

.rf-DataCard:hover {
	box-shadow: var(--drop-shadow__regular);
}

.rf-DataCard .rf-Separator {
	margin: 0 auto;
	width: calc(100% - 10px);
	background: var(--color__gray--light);
}

.rf-DataCard-thumbnail {
	width: 100%;
	height: 120px;
}

.rf-DataCard-thumbnail > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rf-DataCard-title {
	padding: 10px;
	font-size: var(--font__size--big);
	text-transform: uppercase;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}

.rf-DataCard-footer {
	padding: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: var(--font__size--small);
}

.rf-DataCard-footer > .rf-Avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 10px;
}

.rf-ActionCard {
	flex-basis: 20%;
	margin: 5px;
	min-width: 222px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: var(--color__gray);
	box-shadow: var(--drop-shadow__inverse);
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	justify-content: center;
	align-items: center;
}

.rf-ActionCard:last-child {
	margin-right: auto;
}

.rf-ActionCard:hover {
	box-shadow: var(--drop-shadow__regular);
}

.rf-ActionCard .rf-Separator {
	margin: 0 auto;
	width: calc(100% - 10px);
	background: var(--color__gray--light);
}

.rf-ActionCard-icon {
	font-size: calc(var(--font__size--big)*4);
}

.rf-ActionCard-label {
	font-size: var(--font__size--big);
	text-transform: uppercase;
	font-weight: 700;
	display: block;
	margin-top: 10px;
}

.rf-Home-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-Home-top > img {
	width: 200px;
	height: auto;
}

.rf-Home-bottom {
	display: flex;
	flex-direction: column;
}

.rf-Button {
	height: var(--button__height);
	min-width: var(--button__width--min);
	max-width: var(--button__width--max);
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	border: 1px solid var(--color__secondary);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.rf-Button:hover {
	box-shadow: var(--button__drop-shadow);
}

.rf-Button-left {
	flex-direction: row;
}

.rf-Button-right {
	flex-direction: row-reverse;
}

.rf-Button-left > .rf-Button-icon {
	margin-right: 10px;
}

.rf-Button-right > .rf-Button-icon {
	margin-left: 10px;
}

.rf-Button-disabled {
	cursor: not-allowed;
	background: var(--color__gray);
	border-color: var(--color__gray--light);
}

.rf-NotFound-left, .rf-NotFound-right, .rf-Separator {
	height: 200px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rf-NotFound-left > img {
	max-width: 200px;
}

.rf-NotFound-right .rf-Button {
	margin: 20px 0 0;
	width: var(--button__width--min);
}

.rf-SignIn-left, .rf-SignIn-right, .rf-Separator {
	height: 200px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rf-SignIn-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rf-SignIn-right .rf-Button {
	margin: 20px 0 0;
	width: var(--button__width--min);
}

.rf-SignIn-left > img {
	max-width: 200px;
	max-height: 200px;
}

.rf-About-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-About-top > img {
	width: 200px;
	height: auto;
}

.rf-About-bottom {
	display: flex;
	flex-direction: column;
}

.rf-MainMenu {
	width: 100%;
	height: var(--main-menu__height);
	box-shadow: var(--drop-shadow__regular);
	background: var(--main-menu__bg);
}

.rf-MainMenu-wrapper {
	height: 100%;
	margin: 0 auto;
	max-width: var(--content__maxWidth);
	display: flex;
}

.rf-MainMenu-logo {
	width: 200px;
	height: 100%;
	font-weight: 700;
	display: flex;
	align-items: center;
}

.rf-MainMenu-logo > a {
	display: flex;
}

.rf-MainMenu-logo > a:hover {
	color: var(--font__color--dark);
}

.rf-MainMenu-logo > a > span {
	margin-left: 2px;
}

.rf-MainMenu-logo > a > .fa {
	margin-top: 4px;
	color: var(--color__pink);
}

.rf-MainMenu-logo > a > .fa:last-child {
	margin-top: 4px;
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
}

.rf-MainMenu-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.rf-MainMenu-link {
	margin-left: 20px;
	padding: 5px 0;
	position: relative;
}

.rf-MainMenu-link:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	height: 2px;
	width: 0;
	background: var(--color__pink);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rf-MainMenu-link:hover:after {
	width: 100%;
	background: var(--color__pink);
}

.rf-MainMenu-link-active:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	height: 2px;
	width: 100%;
	background: var(--color__pink);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rf-MainMenu-link > .fa {
	margin-right: 5px;
	color: var(--color__pink);
}

/* User Dropdown */

.rf-MainMenu-dropdown {
	position: relative;
	margin-left: 20px;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	width: 180px;
}

.rf-MainMenu-dropdownTrigger {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
	background-color: var(--color__gray);
	padding: 0 10px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: relative;
	z-index: 2;
}

.rf-MainMenu-dropdownTrigger .rf-Avatar {
	flex-shrink: 0;
}

.rf-MainMenu-dropdownTrigger:hover {
	background-color: var(--color__gray--light);
}

.rf-MainMenu-dropdownTriggerName {
	margin-right: 10px;
}

.rf-MainMenu-dropdownMenu {
	margin-top: -100%;
	position: relative;
	width: 100%;
	z-index: 1;
	background: var(--color__gray);
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.rf-MainMenu-dropdownItem {
	cursor: pointer;
	padding: 0 10px;
	height: 50px;
	display: flex;
	align-items: center;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rf-MainMenu-dropdownItem:hover {
	background-color: var(--color__gray--light);
}

.rf-MainMenu-dropdownItem > .fa {
	width: 26px;
	color: var(--color__pink);
}

.rf-MainMenu-dropdown:hover > .rf-MainMenu-dropdownMenu {
	margin-top: 0;
}

.rf-Toaster {
	-webkit-animation: var(--animation__slide_in--up);
	        animation: var(--animation__slide_in--up);
	z-index: 9999;
	display: flex;
	justify-content: center;
	min-width: 250px;
	min-height: 40px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	background: var(--toaster__bg);
	color: var(--font__color--primary);
	font-weight: 700;
	box-shadow: var(--drop-shadow__inverse);
}

.rf-Toaster-actionButton {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	color: var(--font__color--secondary);
}

.rf-Toaster-actionButton:hover {
	background: rgba(0,0,0,0.1);
}

.rf-Toaster-toastText {
	display: flex;
	align-items: center;
	margin: 10px;
	max-width: 300px;
}

.rf-MarkdownRenderer {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	line-height: 1.5;
	color: var(--font__color--primary);
	font-size: 12px;
	padding: 20px 0;
	word-wrap: break-word;
	border-top: 1px solid var(--color__gray--light);
	border-bottom: 1px solid var(--color__gray--light);
}

.rf-MarkdownRenderer .pl-c {
	color: #6a737d;
}

.rf-MarkdownRenderer .pl-c1,
.rf-MarkdownRenderer .pl-s .pl-v {
	color: #005cc5;
}

.rf-MarkdownRenderer .pl-e,
.rf-MarkdownRenderer .pl-en {
	color: #6f42c1;
}

.rf-MarkdownRenderer .pl-smi,
.rf-MarkdownRenderer .pl-s .pl-s1 {
	color: var(--font__color--primary);
}

.rf-MarkdownRenderer .pl-ent {
	color: #22863a;
}

.rf-MarkdownRenderer .pl-k {
	color: #d73a49;
}

.rf-MarkdownRenderer .pl-s,
.rf-MarkdownRenderer .pl-pds,
.rf-MarkdownRenderer .pl-s .pl-pse .pl-s1,
.rf-MarkdownRenderer .pl-sr,
.rf-MarkdownRenderer .pl-sr .pl-cce,
.rf-MarkdownRenderer .pl-sr .pl-sre,
.rf-MarkdownRenderer .pl-sr .pl-sra {
	color: #032f62;
}

.rf-MarkdownRenderer .pl-v,
.rf-MarkdownRenderer .pl-smw {
	color: #e36209;
}

.rf-MarkdownRenderer .pl-bu {
	color: #b31d28;
}

.rf-MarkdownRenderer .pl-ii {
	color: #fafbfc;
	background-color: #b31d28;
}

.rf-MarkdownRenderer .pl-c2 {
	color: #fafbfc;
	background-color: #d73a49;
}

.rf-MarkdownRenderer .pl-c2:before {
	content: '^M';
}

.rf-MarkdownRenderer .pl-sr .pl-cce {
	font-weight: bold;
	color: #22863a;
}

.rf-MarkdownRenderer .pl-ml {
	color: #735c0f;
}

.rf-MarkdownRenderer .pl-mh,
.rf-MarkdownRenderer .pl-mh .pl-en,
.rf-MarkdownRenderer .pl-ms {
	font-weight: bold;
	color: #005cc5;
}

.rf-MarkdownRenderer .pl-mi {
	font-style: italic;
	color: var(--font__color--primary);
}

.rf-MarkdownRenderer .pl-mb {
	font-weight: bold;
	color: var(--font__color--primary);
}

.rf-MarkdownRenderer .pl-md {
	color: #b31d28;
	background-color: #ffeef0;
}

.rf-MarkdownRenderer .pl-mi1 {
	color: #22863a;
	background-color: #f0fff4;
}

.rf-MarkdownRenderer .pl-mc {
	color: #e36209;
	background-color: #ffebda;
}

.rf-MarkdownRenderer .pl-mi2 {
	color: var(--color__gray);
	background-color: #005cc5;
}

.rf-MarkdownRenderer .pl-mdr {
	font-weight: bold;
	color: #6f42c1;
}

.rf-MarkdownRenderer .pl-ba {
	color: #586069;
}

.rf-MarkdownRenderer .pl-sg {
	color: #959da5;
}

.rf-MarkdownRenderer .pl-corl {
	text-decoration: underline;
	color: #032f62;
}

.rf-MarkdownRenderer .octicon {
	display: inline-block;
	fill: currentColor;
	vertical-align: text-bottom;
}

.rf-MarkdownRenderer a {
	background-color: transparent;
	color: var(--font__color--secondary);
	text-decoration: none;
}

.rf-MarkdownRenderer a:active,
.rf-MarkdownRenderer a:hover {
	outline-width: 0;
}

.rf-MarkdownRenderer strong {
	font-weight: 700;
}

.rf-MarkdownRenderer h1 {
	margin: 0.67em 0;
	font-weight: 700;
	padding-bottom: 0.3em;
	font-size: 2em;
	border-bottom: 1px solid #eaecef;
}

.rf-MarkdownRenderer img {
	border-style: none;
	max-width: 100%;
	box-sizing: content-box;
}

.rf-MarkdownRenderer code,
.rf-MarkdownRenderer kbd,
.rf-MarkdownRenderer pre {
	font-size: 1em;
}

.rf-MarkdownRenderer hr {
	box-sizing: content-box;
	overflow: hidden;
	background: transparent;
	height: 0.25em;
	padding: 0;
	margin: 24px 0;
	border: 0;
	border-bottom: 1px solid var(--color__gray--light);
}

.rf-MarkdownRenderer input {
	font: inherit;
	margin: 0;
	overflow: visible;
}

.rf-MarkdownRenderer [type='checkbox'] {
	box-sizing: border-box;
	margin-right: 10px;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	cursor: default;
}

.rf-MarkdownRenderer * {
	box-sizing: border-box;
}

.rf-MarkdownRenderer a:hover {
	text-decoration: underline;
}

.rf-MarkdownRenderer hr:before {
	display: table;
	content: '';
}

.rf-MarkdownRenderer hr:after {
	display: table;
	clear: both;
	content: '';
}

.rf-MarkdownRenderer table {
	border-spacing: 0;
	border-collapse: collapse;
	display: block;
	width: 100%;
	overflow: auto;
}

.rf-MarkdownRenderer td,
.rf-MarkdownRenderer th {
	padding: 0;
}

.rf-MarkdownRenderer h1,
.rf-MarkdownRenderer h2,
.rf-MarkdownRenderer h3,
.rf-MarkdownRenderer h4,
.rf-MarkdownRenderer h5,
.rf-MarkdownRenderer h6 {
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: 700;
	line-height: 1.25;
}

.rf-MarkdownRenderer h2 {
	font-weight: 700;
	padding-bottom: 0.3em;
	font-size: 1.5em;
	border-bottom: 1px solid #eaecef;
}

.rf-MarkdownRenderer h3 {
	font-size: 1.25em;
	font-weight: 700;
}

.rf-MarkdownRenderer h4 {
	font-weight: 700;
	font-size: 1em;
}

.rf-MarkdownRenderer h5 {
	font-weight: 700;
	font-size: 0.88em;
}

.rf-MarkdownRenderer h6 {
	font-weight: 700;
	font-size: 0.85em;
	color: #6a737d;
}

.rf-MarkdownRenderer p {
	margin-top: 0;
	margin-bottom: 10px;
}

.rf-MarkdownRenderer blockquote {
	margin: 0;
	padding: 0 1em;
	color: #6a737d;
	border-left: 0.25em solid var(--color__gray--light);
}

.rf-MarkdownRenderer ul,
.rf-MarkdownRenderer ol {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 2em;
}

.rf-MarkdownRenderer ol ol,
.rf-MarkdownRenderer ul ol {
	list-style-type: lower-roman;
}

.rf-MarkdownRenderer ul ul ol,
.rf-MarkdownRenderer ul ol ol,
.rf-MarkdownRenderer ol ul ol,
.rf-MarkdownRenderer ol ol ol {
	list-style-type: lower-alpha;
}

.rf-MarkdownRenderer dd {
	margin-left: 0;
}

.rf-MarkdownRenderer code {
	padding: 0.2em 0.4em;
	margin: 0;
	font-size: 85%;
	background-color: rgba(27,31,35,0.05);
	border-radius: 3px;
}

.rf-MarkdownRenderer pre {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	word-wrap: normal;
}

.rf-MarkdownRenderer .pl-0 {
	padding-left: 0 !important;
}

.rf-MarkdownRenderer .pl-1 {
	padding-left: 4px !important;
}

.rf-MarkdownRenderer .pl-2 {
	padding-left: 8px !important;
}

.rf-MarkdownRenderer .pl-3 {
	padding-left: 16px !important;
}

.rf-MarkdownRenderer .pl-4 {
	padding-left: 24px !important;
}

.rf-MarkdownRenderer .pl-5 {
	padding-left: 32px !important;
}

.rf-MarkdownRenderer .pl-6 {
	padding-left: 40px !important;
}

.rf-MarkdownRenderer:before {
	display: table;
	content: '';
}

.rf-MarkdownRenderer:after {
	display: table;
	clear: both;
	content: '';
}

.rf-MarkdownRenderer > *:first-child {
	margin-top: 0 !important;
}

.rf-MarkdownRenderer > *:last-child {
	margin-bottom: 0 !important;
}

.rf-MarkdownRenderer a:not([href]) {
	color: inherit;
	text-decoration: none;
}

.rf-MarkdownRenderer .anchor {
	float: left;
	padding-right: 4px;
	margin-left: -20px;
	line-height: 1;
}

.rf-MarkdownRenderer .anchor:focus {
	outline: none;
}

.rf-MarkdownRenderer p,
.rf-MarkdownRenderer blockquote,
.rf-MarkdownRenderer ul,
.rf-MarkdownRenderer ol,
.rf-MarkdownRenderer dl,
.rf-MarkdownRenderer table,
.rf-MarkdownRenderer pre {
	margin-top: 0;
	margin-bottom: 16px;
}

.rf-MarkdownRenderer blockquote > :first-child {
	margin-top: 0;
}

.rf-MarkdownRenderer blockquote > :last-child {
	margin-bottom: 0;
}

.rf-MarkdownRenderer kbd {
	padding: 3px 5px;
	color: #444d56;
	vertical-align: middle;
	background-color: #fafbfc;
	border-radius: 3px;
	display: inline-block;
	font: 11px 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', 'Courier', monospace;
	line-height: 10px;
	border: solid 1px #d1d5da;
	border-bottom-color: var(--color__gray--light);
	box-shadow: inset 0 -1px 0 var(--color__gray--light);
}

.rf-MarkdownRenderer h1 .octicon-link,
.rf-MarkdownRenderer h2 .octicon-link,
.rf-MarkdownRenderer h3 .octicon-link,
.rf-MarkdownRenderer h4 .octicon-link,
.rf-MarkdownRenderer h5 .octicon-link,
.rf-MarkdownRenderer h6 .octicon-link {
	color: #1b1f23;
	vertical-align: middle;
	visibility: hidden;
}

.rf-MarkdownRenderer h1:hover .anchor,
.rf-MarkdownRenderer h2:hover .anchor,
.rf-MarkdownRenderer h3:hover .anchor,
.rf-MarkdownRenderer h4:hover .anchor,
.rf-MarkdownRenderer h5:hover .anchor,
.rf-MarkdownRenderer h6:hover .anchor {
	text-decoration: none;
}

.rf-MarkdownRenderer h1:hover .anchor .octicon-link,
.rf-MarkdownRenderer h2:hover .anchor .octicon-link,
.rf-MarkdownRenderer h3:hover .anchor .octicon-link,
.rf-MarkdownRenderer h4:hover .anchor .octicon-link,
.rf-MarkdownRenderer h5:hover .anchor .octicon-link,
.rf-MarkdownRenderer h6:hover .anchor .octicon-link {
	visibility: visible;
}

.rf-MarkdownRenderer ul ul,
.rf-MarkdownRenderer ul ol,
.rf-MarkdownRenderer ol ol,
.rf-MarkdownRenderer ol ul {
	margin-top: 0;
	margin-bottom: 0;
}

.rf-MarkdownRenderer li {
	word-wrap: break-all;
}

.rf-MarkdownRenderer li > p {
	margin-top: 16px;
}

.rf-MarkdownRenderer li + li {
	margin-top: 0.25em;
}

.rf-MarkdownRenderer dl {
	padding: 0;
}

.rf-MarkdownRenderer dl dt {
	padding: 0;
	margin-top: 16px;
	font-size: 1em;
	font-style: italic;
	font-weight: 700;
}

.rf-MarkdownRenderer dl dd {
	padding: 0 16px;
	margin-bottom: 16px;
}

.rf-MarkdownRenderer table th {
	font-weight: 700;
}

.rf-MarkdownRenderer table th,
.rf-MarkdownRenderer table td {
	padding: 6px 13px;
	border: 1px solid var(--color__gray--light);
}

.rf-MarkdownRenderer table tr {
	border-top: 1px solid var(--color__gray--light);
}

.rf-MarkdownRenderer table tr:nth-child(2n) {
	background-color: var(--color__gray);
}

.rf-MarkdownRenderer img[align='right'] {
	padding-left: 20px;
}

.rf-MarkdownRenderer img[align='left'] {
	padding-right: 20px;
}

.rf-MarkdownRenderer pre > code {
	padding: 0;
	margin: 0;
	font-size: 100%;
	word-break: normal;
	white-space: pre;
	background: transparent;
	border: 0;
}

.rf-MarkdownRenderer .highlight {
	margin-bottom: 16px;
}

.rf-MarkdownRenderer .highlight pre {
	margin-bottom: 0;
	word-break: normal;
}

.rf-MarkdownRenderer .highlight pre,
.rf-MarkdownRenderer pre {
	padding: 16px;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: var(--color__gray);
	border-radius: 3px;
}

.rf-MarkdownRenderer pre code {
	display: inline;
	max-width: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	line-height: inherit;
	word-wrap: normal;
	background-color: transparent;
	border: 0;
}

.rf-MarkdownRenderer .full-commit .btn-outline:not(:disabled):hover {
	color: #005cc5;
	border-color: #005cc5;
}

.rf-MarkdownRenderer :checked + .radio-label {
	position: relative;
	z-index: 1;
	border-color: var(--font__color--primary);
}

.rf-MarkdownRenderer .task-list-item {
	list-style-type: none;
}

.rf-MarkdownRenderer .task-list-item + .task-list-item {
	margin-top: 3px;
}

.rf-MarkdownRenderer .task-list-item input {
	margin: 0 0.2em 0.25em -1.6em;
	vertical-align: middle;
}

.rf-MarkdownEditor {
	width: 100%;
}

.rf-MarkdownEditor-editor {
	height: inherit;
	min-height: 300px;
}

.rf-MarkdownEditor-preview {
	height: auto;
	min-height: 300px;
}

.rf-MarkdownEditor-tabs {
	height: 40px;
	width: 100%;
	display: flex;
}

.rf-MarkdownEditor-tab {
	height: 100%;
	width: 50%;
	flex-basis: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: var(--color__gray);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rf-MarkdownEditor-tab:hover {
	background: var(--color__gray--light);
}

.rf-MarkdownEditor-tab-active {
	background: var(--color__gray--light);
}

.rf-WithDropZone {
	width: 100%;
	cursor: pointer;
}

.rf-WithDropZone .DropZone {
	width: auto;
	width: initial;
	height: auto;
	height: initial;
	border-width: 0;
	border-color: transparent;
	border-style: none;
	border-radius: 0;
	cursor: pointer;
}

.rf-WithDropZone .DropZone-disabled {
	cursor: progress;
}

.rf-InputField {
	width: 100%;
	height: 40px;
	box-shadow: none;
	box-sizing: border-box;
	padding: 0 10px;
	border: 1px solid var(--color__gray--light);
	background-color: var(--color__gray);
	color: var(--font__color--primary);
	font-size: var(--font__size--base);
	font-weight: 700;
}

.rf-InputField:hover,
.rf-InputField:focus,
.rf-InputField:active {
	outline: none;
	box-shadow: none;
}

.rf-ProjectForm {
	display: flex;
	width: 100%;
	flex-direction: column;
	height: auto;
	flex-shrink: 0;
}

.rf-ProjectForm i {
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
	display: block;
}

.rf-ProjectForm h4 {
	display: inline-block;
}

.rf-ProjectForm-required {
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
	font-style: italic;
	margin-left: 10px;
}

.rf-ProjectForm-top {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-shrink: 0;
}

.rf-ProjectForm-bottom {
	padding-bottom: 40px;
}

.rf-ProjectForm-top-left,
.rf-ProjectForm-top-right {
	width: 50%;
}

.rf-ProjectForm-top-left {
	padding-right: 5px;
}

.rf-ProjectForm-top-right {
	padding-left: 5px;
}

.rf-ProjectForm-top-right .rf-WithDropzone {
	height: 128px;
	width: 100%;
	border: 1px solid var(--color__gray--light);
	background-color: var(--color__gray);
	color: #757575;
	font-size: var(--font__size--base);
	font-weight: 700;
	cursor: pointer;
}

.rf-ProjectForm .rf-MarkdownEditor {
	margin-top: 20px;
}

.rf-ProjectForm-footer {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.rf-ProjectForm-footer .rf-Button {
	width: 50%;
	max-width: unset;
	height: 40px;
	margin-right: 10px;
}

.rf-ProjectForm-footer .rf-Button:last-child {
	margin-right: 0;
}

.rf-ProjectForm-dropzoneContent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
}

.rf-ProjectForm-dropzoneContent .fa {
	font-size: var(--font__size--giant);
}

.rf-ProjectForm-dropzoneContent img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.rf-ProjectNew-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-ProjectNew-header {
	display: flex;
	flex-direction: column;
}

.rf-MissingProject-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-MissingProject-top > img {
	width: 200px;
	height: auto;
}

.rf-MissingProject-bottom {
	display: flex;
	flex-direction: column;
}

.rf-ForbiddenPage-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-ForbiddenPage-top > img {
	width: 200px;
	height: auto;
}

.rf-ForbiddenPage-bottom {
	display: flex;
	flex-direction: column;
}

.rf-ProjectEdit-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-ProjectEdit-header {
	display: flex;
	flex-direction: column;
}

.rf-MyProjects-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-MyProjects-top > img {
	width: 200px;
	height: auto;
}

.rf-MyProjects-bottom {
	display: flex;
	flex-direction: column;
}

.rf-MyProjects-floatingButton {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--color__secondary);
	width: 40px;
	height: 40px;
	box-shadow: var(--drop-shadow__inverse);
	border-radius: 50%;
	position: absolute;
	right: 10px;
	bottom: 20px;
	z-index: 9998;
}

.rf-ProjectDetails-description {
	width: 100%;
}

.rf-ProjectDetails-header {
	width: 100%;
	display: flex;
	margin-bottom: 20px;
	height: 200px;
	flex-shrink: 0;
}

.rf-ProjectDetails-header-left {
	width: 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-right: 10px;
}

.rf-ProjectDetails-header-right {
	width: 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-left: 10px;
}

.rf-ProjectDetails-header-left > h1 {
	margin-top: 0;
	margin-bottom: 5px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rf-ProjectDetails-header-left > a {
	color: var(--font__color--secondary);
}

.rf-ProjectDetails-header-left-info {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.rf-ProjectDetails-header-left-date {
	margin-top: 5px;
	display: flex;
	align-items: center;
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
}

.rf-ProjectDetails-header-left > span .rf-Avatar {
	margin-left: 10px;
}

.rf-ProjectDetails-header-right > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rf-ProjectDetails-detailControls {
	width: 100%;
	display: flex;
	margin-top: auto;
}

.rf-ProjectDetails-detailControls .rf-Button {
	margin-right: 10px;
}

.rf-ProjectDetails-comments {
	width: 100%;
	margin: 40px 0;
}

