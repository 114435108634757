.rf-InputField {
	width: 100%;
	height: 40px;
	box-shadow: none;
	box-sizing: border-box;
	padding: 0 10px;
	border: 1px solid var(--color__gray--light);
	background-color: var(--color__gray);
	color: var(--font__color--primary);
	font-size: var(--font__size--base);
	font-weight: 700;
}

.rf-InputField:hover,
.rf-InputField:focus,
.rf-InputField:active {
	outline: none;
	box-shadow: none;
}
