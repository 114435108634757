.rf-MarkdownEditor {
	width: 100%;
}

.rf-MarkdownEditor-editor {
	height: inherit;
	min-height: 300px;
}

.rf-MarkdownEditor-preview {
	height: auto;
	min-height: 300px;
}

.rf-MarkdownEditor-tabs {
	height: 40px;
	width: 100%;
	display: flex;
}

.rf-MarkdownEditor-tab {
	height: 100%;
	width: 50%;
	flex-basis: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: var(--color__gray);
	transition: all 0.3s ease-in-out;
}

.rf-MarkdownEditor-tab:hover {
	background: var(--color__gray--light);
}

.rf-MarkdownEditor-tab-active {
	background: var(--color__gray--light);
}
