.cm-s-ratefolio.CodeMirror, .cm-s-ratefolio .CodeMirror-gutters {
	background-color: var(--color__gray) !important;
	color: var(--color__white) !important;
	border: 1px solid var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-gutters {
	color: var(--color__gray--light) !important;
	border: none;
	border-right: 1px solid var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-sizer {
	padding: 10px 0;
}

.cm-s-ratefolio .CodeMirror-cursor {
	border-left: solid thin var(--color__white--dark);
}

.cm-s-ratefolio .CodeMirror-linenumber {
	color: var(--color__pink);
}

.cm-s-ratefolio .CodeMirror-selected {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio .CodeMirror-line::selection, .cm-s-ratefolio .CodeMirror-line > span::selection, .cm-s-ratefolio .CodeMirror-line > span > span::selection {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio .CodeMirror-line::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span::-moz-selection, .cm-s-ratefolio .CodeMirror-line > span > span::-moz-selection {
	background: var(--color__gray--dark);
}

.cm-s-ratefolio span.cm-comment {
	color: #6272a4;
}

.cm-s-ratefolio span.cm-string, .cm-s-ratefolio span.cm-string-2 {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-number {
	color: #bd93f9;
}

.cm-s-ratefolio span.cm-variable {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-variable-2 {
	color: var(--color__white);
}

.cm-s-ratefolio span.cm-def {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-operator {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-keyword {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-atom {
	color: #bd93f9;
}

.cm-s-ratefolio span.cm-meta {
	color: #f8f8f2;
}

.cm-s-ratefolio span.cm-tag {
	color: var(--color__pink);
}

.cm-s-ratefolio span.cm-attribute {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-qualifier {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-property {
	color: #66d9ef;
}

.cm-s-ratefolio span.cm-builtin {
	color: #50fa7b;
}

.cm-s-ratefolio span.cm-variable-3, .cm-s-ratefolio span.cm-type {
	color: #ffb86c;
}

.cm-s-ratefolio .CodeMirror-activeline-background {
	background: var(--color__gray--light);
}

.cm-s-ratefolio .CodeMirror-matchingbracket {
	text-decoration: underline;
	color: var(--color__white) !important;
}
