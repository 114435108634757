.rf-Avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
}

.rf-Avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
