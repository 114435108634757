.rf-SignIn-left, .rf-SignIn-right, .rf-Separator {
	height: 200px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rf-SignIn-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rf-SignIn-right .rf-Button {
	margin: 20px 0 0;
	width: var(--button__width--min);
}

.rf-SignIn-left > img {
	max-width: 200px;
	max-height: 200px;
}
