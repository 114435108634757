.rf-ProjectNew-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-ProjectNew-header {
	display: flex;
	flex-direction: column;
}
