.rf-WithDropZone {
	width: 100%;
	cursor: pointer;
}

.rf-WithDropZone .DropZone {
	width: initial;
	height: initial;
	border-width: 0;
	border-color: transparent;
	border-style: none;
	border-radius: 0;
	cursor: pointer;
}

.rf-WithDropZone .DropZone-disabled {
	cursor: progress;
}
