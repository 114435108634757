.rf-ProjectForm {
	display: flex;
	width: 100%;
	flex-direction: column;
	height: auto;
	flex-shrink: 0;
}

.rf-ProjectForm i {
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
	display: block;
}

.rf-ProjectForm h4 {
	display: inline-block;
}

.rf-ProjectForm-required {
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
	font-style: italic;
	margin-left: 10px;
}

.rf-ProjectForm-top {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-shrink: 0;
}

.rf-ProjectForm-bottom {
	padding-bottom: 40px;
}

.rf-ProjectForm-top-left,
.rf-ProjectForm-top-right {
	width: 50%;
}

.rf-ProjectForm-top-left {
	padding-right: 5px;
}

.rf-ProjectForm-top-right {
	padding-left: 5px;
}

.rf-ProjectForm-top-right .rf-WithDropzone {
	height: 128px;
	width: 100%;
	border: 1px solid var(--color__gray--light);
	background-color: var(--color__gray);
	color: #757575;
	font-size: var(--font__size--base);
	font-weight: 700;
	cursor: pointer;
}

.rf-ProjectForm .rf-MarkdownEditor {
	margin-top: 20px;
}

.rf-ProjectForm-footer {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.rf-ProjectForm-footer .rf-Button {
	width: 50%;
	max-width: unset;
	height: 40px;
	margin-right: 10px;
}

.rf-ProjectForm-footer .rf-Button:last-child {
	margin-right: 0;
}

.rf-ProjectForm-dropzoneContent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
}

.rf-ProjectForm-dropzoneContent .fa {
	font-size: var(--font__size--giant);
}

.rf-ProjectForm-dropzoneContent img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
