.rf-ProjectDetails-description {
	width: 100%;
}

.rf-ProjectDetails-header {
	width: 100%;
	display: flex;
	margin-bottom: 20px;
	height: 200px;
	flex-shrink: 0;
}

.rf-ProjectDetails-header-left {
	width: 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-right: 10px;
}

.rf-ProjectDetails-header-right {
	width: 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-left: 10px;
}

.rf-ProjectDetails-header-left > h1 {
	margin-top: 0;
	margin-bottom: 5px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rf-ProjectDetails-header-left > a {
	color: var(--font__color--secondary);
}

.rf-ProjectDetails-header-left-info {
	margin-top: 20px;
	display: flex;
	align-items: center;
}

.rf-ProjectDetails-header-left-date {
	margin-top: 5px;
	display: flex;
	align-items: center;
	font-size: var(--font__size--small);
	color: var(--font__color--dark);
}

.rf-ProjectDetails-header-left > span .rf-Avatar {
	margin-left: 10px;
}

.rf-ProjectDetails-header-right > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rf-ProjectDetails-detailControls {
	width: 100%;
	display: flex;
	margin-top: auto;
}

.rf-ProjectDetails-detailControls .rf-Button {
	margin-right: 10px;
}

.rf-ProjectDetails-comments {
	width: 100%;
	margin: 40px 0;
}
