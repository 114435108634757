/* Toast Animations */

@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

/* Header Animations */

@keyframes typing {
	from {
		width: 100%;
	}

	to {
		width: 0;
	}
}

@keyframes cursor {
	50% {
		border-color: var(--color__secondary);
	}
}

/* Loader Animations */

@keyframes pulse_1 {
	0% {
		transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		transform: translate3d(0, 0, 0) scale(1.5);
		opacity: 0;
	}
}

@keyframes pulse_2 {
	0% {
		transform: translate3d(0, 0, 0) scale(0);
		opacity: 1;
	}

	100% {
		transform: translate3d(0, 0, 0) scale(1);
		opacity: 0;
	}
}
