.rf-ForbiddenPage-section, .rf-Separator {
	width: 100%;
	max-width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.rf-ForbiddenPage-top > img {
	width: 200px;
	height: auto;
}

.rf-ForbiddenPage-bottom {
	display: flex;
	flex-direction: column;
}
