.rf-Separator {
	background: var(--color__secondary);
}

.rf-Separator-horizontal {
	height: 1px !important;
	margin: 40px 0;
}

.rf-Separator-vertical {
	width: 1px !important;
	margin: 0 40px;
}
